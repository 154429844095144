import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";

import plus from "../../imgs/plus.png";
import plusW from "../../imgs/plusW.png";
import checkImgW from "../../imgs/checkW.png";
import checkImg from "../../imgs/check.png";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

//make finance atleast 1

let Store = ({ uid, darkmode, preferences, togglePrefSettings, isAdmin }) => {
  let [menu, setMenu] = useState(preferences.menu);
  let [display, setDisplay] = useState(null);
  let [checkout, setCheckout] = useState([]);

  useEffect(() => {
    getUser();
  }, [uid]);

  let getUser = async () => {
    console.log("eh");

    let res = await Axios.get(`https://quotron.co/user/${uid}`);
    if (res.data?.length > 0) {
      let user = res.data[0];
      console.log("display in", user.display || null);
      setDisplay(user.display || null);
    }
  };

  let menuTotal = {
    finance: [
      { name: "stocks", on: true },
      { name: "crypto", on: true },
      { name: "markets", on: true },
      { name: "forex", on: true },
      { name: "comms", on: true },
    ],
    sports: [
      { name: "mlb", on: true },
      { name: "nfl", on: true },
      { name: "ncaa", on: true },
      { name: "nba", on: true },
      { name: "nhl", on: true },
      { name: "nippon", on: true, isAdmin: true, cost: 2.5 },
    ],
    weather: [
      {
        name: "all",
        on: true,
      },
    ],
  };

  //payment idea
  /*
      <div
        style={{
          opacity: 0.9,
          fontSize: 15,
          marginRight: 8,
        }}
        className={"profileTextSub"}
      >{`$5/ mo`}</div>
  */

  let onApprove = async (data, actions) => {
    console.log("onApprove", data, actions);
    let orderId = data.orderID;
    let subscriptionId = data.subscriptionID;
    console.log("orderId", orderId);
    console.log("subscriptionId", subscriptionId);
  };

  let onCancel = async (data, actions) => {
    console.log("onCancel", data, actions);
  };

  let onError = async (data, actions) => {
    console.log("onError", data, actions);
  };

  let refreshPaypal = async () => {
    //let res = await Axios.get(`http://localhost:8080/refreshPaypal `);
    let res = await Axios.get(`http://dev.quotron.co:8080/refreshPaypal `);
    console.log("refreshPaypal res", res);
  };

  let addToCheckout = async (type, name) => {
    console.log("addToCheckout", type, name);
    let newCheckout = [...checkout];
    newCheckout.push({ type, name });
    setCheckout(newCheckout);
  };

  let removeFromCheckout = async (type, name) => {
    console.log("removeFromCheckout", type, name);
    let newCheckout = checkout.filter((a) => a.type != type || a.name != name);
    setCheckout(newCheckout);
  };

  let makeActive = async (type, name) => {
    console.log("makeActive", type, name);
    if (name == "nippon") {
      addToCheckout(type, name);
      //refreshPaypal();
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);

      return;
    }

    let newMenu = { ...menu };
    if (!newMenu[type]) {
      newMenu[type] = [];
    }
    newMenu[type].push({ name, on: true });
    setMenu(newMenu);
    let toggleRes = await togglePrefSettings("menu", newMenu);
    addToLines(name);
  };

  let makeInactive = async (type, name) => {
    console.log("makeInactive", type, name);
    let newMenu = { ...menu };
    newMenu[type] = newMenu[type].filter((a) => a.name != name);
    console.log("newMenu", newMenu);
    setMenu(newMenu);
    let toggleRes = await togglePrefSettings("menu", newMenu);
    removeFromLines(name);
  };

  let addToLines = async (name) => {
    if (name == "all") {
      name = "weather";
    }
    console.log("addToLines", name);
    let line1Data = preferences?.line1Data;
    let line2Data = preferences?.line2Data;

    if (!line1Data?.includes(name)) {
      line1Data.push(name);
    }
    if (!line2Data?.includes(name)) {
      line2Data.push(name);
    }

    //legacy
    let toggleRes = await togglePrefSettings("line1Data", line1Data);
    let toggleRes2 = await togglePrefSettings("line2Data", line2Data);

    //new
    if (display) {
      Object.keys(display).forEach((key) => {
        let newDisplay1;
        let newDisplay2;

        let dDisplay = display[key] || { line1Data: [], line2Data: [] };
        console.log("dDisplay", dDisplay);

        if (!dDisplay?.line1Data?.includes(name)) {
          dDisplay.line1Data.push(name);
          newDisplay1 = dDisplay.line1Data;

          console.log("send newDisplay1", key, newDisplay1);

          setDisplay((prevDisplay) => {
            let updatedDisplay = { ...prevDisplay };
            updatedDisplay[key] = { ...updatedDisplay[key], line1Data: newDisplay1 };
            return updatedDisplay;
          });

          Axios.post("https://quotron.co/display", { uid, type: "line1Data", value: newDisplay1, dId: key }).then((res) => {
            console.log("setDiplay line1Data res", res.data);
          });
        }
      });
    }
  };

  let removeFromLines = async (name) => {
    if (name == "all") {
      name = "weather";
    }
    console.log("removeFromLines", name);
    let line1Data = preferences?.line1Data;
    let line2Data = preferences?.line2Data;

    if (line1Data?.includes(name)) {
      line1Data = line1Data?.filter((a) => a != name);
    }
    if (line2Data?.includes(name)) {
      line2Data = line2Data?.filter((a) => a != name);
    }

    //legacy
    let toggleRes = await togglePrefSettings("line1Data", line1Data);
    let toggleRes2 = await togglePrefSettings("line2Data", line2Data);

    //new
    if (display) {
      Object.keys(display).forEach((key) => {
        let newDisplay1;
        let newDisplay2;

        if (display[key].line1Data?.includes(name)) {
          newDisplay1 = display[key].line1Data?.filter((a) => a != name);
          console.log("send newDisplay1", key, newDisplay1);

          setDisplay((prevDisplay) => {
            let updatedDisplay = { ...prevDisplay };
            updatedDisplay[key] = { ...updatedDisplay[key], line1Data: newDisplay1 };
            return updatedDisplay;
          });

          Axios.post("https://quotron.co/display", { uid, type: "line1Data", value: newDisplay1, dId: key }).then((res) => {
            console.log("setDiplay line1Data res", res.data);
            // Update display locally
          });
        }
        if (display[key].line2Data?.includes(name)) {
          newDisplay2 = display[key].line2Data?.filter((a) => a != name);
          console.log("send newDisplay2", key, newDisplay2);

          setDisplay((prevDisplay) => {
            let updatedDisplay = { ...prevDisplay };
            updatedDisplay[key] = { ...updatedDisplay[key], line2Data: newDisplay2 };
            return updatedDisplay;
          });

          Axios.post("https://quotron.co/display", { uid, type: "line2Data", value: newDisplay2, dId: key }).then((res) => {
            console.log("setDiplay line2Data res", res.data);
            // Update display locally
          });
        }
      });
    }
  };

  let sendMenu = async (newMenu) => {
    var options = {
      method: "POST",
      url: "https://quotron.co/sendMenu",
      data: {
        uid,
        menu: newMenu,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("sendMenu", options);

    try {
      let res = await Axios.request(options);
      console.log("sendMenu res", res);
    } catch (err) {
      console.log("addToUser err", err);
    }

    console.log("res", res);
  };

  let financeRow = menuTotal.finance.map((a, k) => {
    let isActive = false;
    if (menu.finance.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    if (isActive) {
      return (
        <div style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }} onClick={() => makeInactive("finance", a.name)}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {a.name}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img src={darkmode ? checkImg : checkImgW} style={{ height: 19, width: 19, opacity: darkmode ? 1 : 0.9 }} />
          </div>
        </div>
      );
    }
    return (
      <div style={darkmode ? { ...local.s2Dark } : { ...local.s2 }} onClick={() => makeActive("finance", a.name)}>
        <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
          {a.name}
        </div>
        <div style={darkmode ? local.plusDark : local.plus}>
          <img src={darkmode ? plusW : plus} style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }} />
        </div>
      </div>
    );
  });

  let sportsRow = menuTotal.sports.map((a, k) => {
    let isActive = false;
    if (menu.sports.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    if (a.isAdmin && !isAdmin) {
      return null;
    }

    let title = a.name?.toUpperCase();

    let showCost = "";
    if (a.cost) {
      showCost = a.cost?.toFixed(2);
    }

    let isCheckout = !!checkout.find((b) => b.type == "sports" && b.name == a.name);

    console.log("isCheckout", a.name, isCheckout);

    if (isActive) {
      return (
        <div style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }} onClick={() => makeInactive("sports", a.name)}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {title}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img src={darkmode ? checkImg : checkImgW} style={{ height: 19, width: 19, opacity: darkmode ? 1 : 0.9 }} />
          </div>
        </div>
      );
    } else if (isCheckout) {
      return (
        <div style={darkmode ? { ...local.s3Dark } : { ...local.s3 }}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {title}
          </div>
          {showCost && (
            <div
              className={"profileTextSub"}
              style={{ display: "flex", alignSelf: "center", justifySelf: "flex-end", color: "#444", fontSize: 16, marginRight: 7 }}
            >{`$${showCost}`}</div>
          )}
          <div style={darkmode ? local.shopDark : local.shop}>
            <div className="fa fa-arrow-down" style={{ color: "#fff", fontSize: 15 }}></div>
          </div>
        </div>
      );
    }
    return (
      <div style={darkmode ? { ...local.s2Dark } : { ...local.s2 }} onClick={() => makeActive("sports", a.name)}>
        <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
          {title}
        </div>
        {showCost && (
          <div
            className={"profileTextSub"}
            style={{ display: "flex", alignSelf: "center", justifySelf: "flex-end", color: "#444", fontSize: 16, marginRight: 7 }}
          >{`$${showCost}`}</div>
        )}
        <div style={darkmode ? local.plusDark : local.plus}>
          <img src={darkmode ? plusW : plus} style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }} />
        </div>
      </div>
    );
  });

  let weatherRow = menuTotal.weather.map((a, k) => {
    let isActive = false;
    if (menu.weather.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    let title = "weather";

    if (isActive) {
      return (
        <div style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }} onClick={() => makeInactive("weather", a.name)}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {title}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img src={darkmode ? checkImg : checkImgW} style={{ height: 19, width: 19, opacity: darkmode ? 1 : 0.9 }} />
          </div>
        </div>
      );
    }
    return (
      <div style={darkmode ? { ...local.s2Dark } : { ...local.s2 }} onClick={() => makeActive("weather", a.name)}>
        <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
          {title}
        </div>
        <div style={darkmode ? local.plusDark : local.plus}>
          <img src={darkmode ? plusW : plus} style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }} />
        </div>
      </div>
    );
  });

  let checkoutList = checkout.map((a, k) => {
    let getRow = menuTotal[a.type].find((b) => b.name == a.name);
    let name = getRow?.name;
    let cost = getRow?.cost?.toFixed(2);
    return (
      <div style={{ display: "flex", flexDirection: "row", alignSelf: "stretch", alignItems: "center" }}>
        <div style={darkmode ? { ...local.s2Dark, flex: 1 } : { ...local.s2, flex: 1 }}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {name}
          </div>
          <div
            className={"profileTextSub"}
            style={{ display: "flex", alignSelf: "center", justifySelf: "flex-end", color: "#444", fontSize: 16, marginRight: 7 }}
          >{`$${cost} /mo`}</div>
        </div>
        <div
          style={{ height: 30, width: 30, display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 4 }}
          onClick={() => removeFromCheckout(a.type, a.name)}
        >
          <img src={darkmode ? closeXDark : closeX} style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }} />
        </div>
      </div>
    );
  });

  return (
    <div className={"prefMenu"} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div style={local.box}>
        <div style={local.header}>
          <div style={local.header2}>{`data feeds`}</div>
        </div>
        <div style={{ ...local.s1 }}>{"finance"}</div>
        {financeRow}
        <div style={{ ...local.s1 }}>{"sports"}</div>
        {sportsRow}
        <div style={{ ...local.s1 }}>{"weather"}</div>
        {weatherRow}
      </div>
      {checkout.length > 0 && (
        <div style={local.box}>
          <div
            className={"profileText"}
            style={{ display: "flex", alignItems: "center", alignSelf: "stretch", justifyContent: "center", marginTop: 6 }}
          >
            <div style={{ opacity: 0.5 }} className="fa fa-arrow-down" aria-hidden="true"></div>
          </div>
          <div style={{ ...local.header2 }}>{`checkout - subscriptions`}</div>
          <div style={{ paddingLeft: 2, paddingRight: 2, marginTop: 5, marginBottom: 6 }}>{checkoutList}</div>
          <PayPalScriptProvider
            options={{
              "client-id": "AVpj7nJ5_LZ3uFy6-6vDuRqIX9izADp_ofsLII1P7hxoDouZhGu6I1ARkQZ91SqLzHIUi_PrsvF7GiBJ",
              vault: true,
              intent: "subscription",
            }}
          >
            <PayPalButtons
              style={{ layout: "horizontal" }}
              fundingSource={"paypal"}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: "P-273170158S204383PM55BDHI",
                  quantity: 1,
                });
              }}
              onApprove={onApprove}
              onCancel={onCancel}
              onError={onError}
            />
          </PayPalScriptProvider>
        </div>
      )}
    </div>
  );
};

let local = {
  box: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 20,
    width: 356,
  },
  header: {
    alignSelf: "stretch",
    border: "#aaa solid 0px",
    borderBottomWidth: 1,
    marginBottom: 6,
    marginRight: 10,
  },
  header2: {
    marginTop: 20,
    marginBottom: -1,
    paddingLeft: 4,
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    color: "#999",
    width: 50,
    marginLeft: 2,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    alignSelf: "flex-start",
    marginBottom: 8,
  },
  s1: {
    height: 40,
    backgroundColor: "#555",
    color: "#fff",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "baseline",
    paddingLeft: 6,
    paddingRight: 14,
    marginTop: 10,
  },
  s2: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    //width: 270,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s2Dark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    //width: 270,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  s3: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 289,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s3Dark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 289,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  s2Active: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 200,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s2ActiveDark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 200,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  check: {
    height: 40,
    border: "1px solid #ccc",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#637dc8",
  },
  checkDark: {
    height: 40,
    border: "1px solid #444",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#637dc8",
  },
  plus: {
    height: 40,
    border: "0px solid #ddd",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
  },
  plusDark: {
    height: 40,
    border: "0px solid #444",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
  },
  shop: {
    height: 40,
    border: "0px solid #ddd",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#89d5c2",
  },
  shopDark: {
    height: 40,
    border: "0px solid #444",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#89d5c2",
  },
};

export default Store;
