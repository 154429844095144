import React, { useState, useEffect } from "react";
import Axios from "axios";

const Data = ({ uid, isMobile, userDevices = [], getUsers }) => {
  let [data, setData] = useState({});
  let [showCrypto, setShowCrypto] = useState(true);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  let refresh = async () => {
    setLoading(true);
    let res = await Axios.get(`http://dev.quotron.co:8080/api/admin/data`);
    let sorted = res.data["crypto-gecko"].sort((a, b) => a.market_cap_rank - b.market_cap_rank);
    res.data["crypto-gecko"] = sorted;

    let sortedSymbols = sorted.map((item) => item.symbol);
    console.log("sortedSymbols", sortedSymbols);

    let sorted2 = sortedSymbols.map((symbol) => {
      let item = res.data["crypto-cmc"].find((item) => item.symbol === symbol);
      return item ? item : { symbol: symbol, price: 0 };
    });
    res.data["crypto-cmc"] = sorted2;

    /*

    let bybitArr = res.data["crypto-bybit"];
    //make symbol for all bybitArr
    bybitArr.map((item, k) => {
      item.symbol = item.symbol.replace("USDT", "");
    });

    let sorted3 = sortedSymbols.map((symbol) => {
      let item = res.data["crypto-bybit"].find((item) => item.symbol === symbol);
      return item ? item : { symbol: symbol, lastPrice: 0 };
    });

    res.data["crypto-bybit"] = sorted3;
    */
    let kukoinArr = res.data["crypto-kukoin"];

    let sorted4 = sortedSymbols.map((symbol) => {
      let item = kukoinArr.find((item) => item.symbol === symbol);
      return item ? item : { symbol: symbol, last: 0 };
    });
    res.data["crypto-kukoin"] = sorted4;

    let poloniexArr = res.data["crypto-poloniex"];
    let sorted5 = sortedSymbols.map((symbol) => {
      let item = poloniexArr.find((item) => item.symbol === symbol);
      return item ? item : { symbol: symbol, markPrice: 0 };
    });
    res.data["crypto-poloniex"] = sorted5;

    console.log("res.data", res.data);

    setData(res.data);
    setLoading(false);
  };

  let symbolCol = data?.["crypto-gecko"]?.map((a, k) => {
    return (
      <div key={k} className={"profileTextSub"}>
        {a.symbol}
      </div>
    );
  });

  let geckoCol = data?.["crypto-gecko"]?.map((a, k) => {
    let showPrice = a.current_price?.toFixed(2);
    return (
      <div key={k} className={"profileTextSub"} style={{ textAlign: "right" }}>
        {showPrice}
      </div>
    );
  });

  let cmcCol = data?.["crypto-cmc"]?.map((a, k) => {
    let showPrice = a.price?.toFixed(2);
    let style = { textAlign: "right" };
    if (a.price == 0.0) {
      style.opacity = 0.4;
      style.textDecoration = "line-through";
    }
    return (
      <div key={k} className={"profileTextSub"} style={style}>
        {showPrice}
      </div>
    );
  });

  let bybitCol = data?.["crypto-bybit"]?.map((a, k) => {
    let price = Number(a.lastPrice);
    let showPrice = price?.toFixed(2);
    let style = { textAlign: "right" };
    if (price == 0.0) {
      style.opacity = 0.4;
      style.textDecoration = "line-through";
    }
    return (
      <div key={k} className={"profileTextSub"} style={style}>
        {showPrice}
      </div>
    );
  });

  let kukoinCol = data?.["crypto-kukoin"]?.map((a, k) => {
    let price = Number(a.last);
    let showPrice = price?.toFixed(2);
    let style = { textAlign: "right" };
    if (price == 0.0) {
      style.opacity = 0.4;
      style.textDecoration = "line-through";
    }
    return (
      <div key={k} className={"profileTextSub"} style={style}>
        {showPrice}
      </div>
    );
  });

  let poloniexCol = data?.["crypto-poloniex"]?.map((a, k) => {
    let price = Number(a.markPrice);
    let showPrice = price?.toFixed(2);
    let style = { textAlign: "right" };
    if (price == 0.0) {
      style.opacity = 0.4;
      style.textDecoration = "line-through";
    }
    return (
      <div key={k} className={"profileTextSub"} style={style}>
        {showPrice}
      </div>
    );
  });

  return (
    <div
      className={"flexColumn profileTextSub"}
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        width: isMobile ? 350 : 700,
      }}
    >
      <button onClick={refresh} style={local.refreshButton}>
        <i className={`fa fa-refresh ${loading ? "fa-spin" : ""}`} />
      </button>
      <div className={"flexRow"} style={{ alignItems: "center", marginBottom: 2 }}>
        <div className={"profileTextSub"}>Crypto</div>
        <div
          onClick={() => setShowCrypto(!showCrypto)}
          className={"profileTextSub"}
          style={{ alignSelf: "flex-start", marginLeft: 10, padding: 4, border: "1px solid #ccc" }}
        >
          <button className={`fa ${showCrypto ? "fa-caret-down" : "fa-caret-right"}`} />
        </div>
      </div>
      {showCrypto && (
        <div style={{ height: 200, overflow: "scroll", border: "0px solid #ccc", borderBottomWidth: 1 }}>
          <div className={"flexRow"} style={{ ...local.rowBig }}>
            <div style={local.colFront}>
              <div className={"profileTextSub"}> - </div>
              {symbolCol}
            </div>
            <div style={local.col}>
              <div className={"profileTextSub"}> gecko </div>
              {geckoCol}
            </div>
            <div style={local.col}>
              <div className={"profileTextSub"}> coinMcap </div>
              {cmcCol}
            </div>
            <div style={local.col}>
              <div className={"profileTextSub"}> kukoin </div>
              {kukoinCol}
            </div>
            <div style={local.col}>
              <div className={"profileTextSub"}> poloniex </div>
              {poloniexCol}
            </div>

            {false && (
              <div style={local.col}>
                <div className={"profileTextSub"}> bybit </div>
                {bybitCol}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Data;

const local = {
  idRow: {
    alignSelf: "stretch",
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 6,
    paddingLeft: 6,
  },
  typeRow: {
    width: 100,
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    cursor: "pointer",
  },
  typeRowActive: {
    width: 100,
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    color: "#FFF",
    backgroundColor: "#888",
    cursor: "pointer",
  },
  col: {
    borderRight: "1px solid #CCC",
    flex: 1,
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
  },
  colFront: {
    borderRight: "1px solid #CCC",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    width: 50,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    border: "1px solid #CCC",
    flex: 1,
  },
  rowBig: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    border: "1px solid #CCC",
  },
  refreshButton: {
    alignSelf: "flex-end",
    marginTop: 10,
    padding: 10,
    border: "1px solid #ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
