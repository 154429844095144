import React from "react";
import Switch from "react-input-switch";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import SwipeToDelete from "react-swipe-to-delete-ios";
import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";
import searchBlack from "../../imgs/search.png";
import rssWhite from "../../imgs/rssWhite3.png";
import { isHoliday } from "../../util/helpers";
import RssButton from "./RssButton";

function mod(n, m) {
  return ((n % m) + m) % m;
}

function ListStocks(props) {
  let { stockData, stockInput, activeStock, networkErr, preferences, isMobile, handleSettingsChange, darkmode, loadingStock } = props;

  let showStockSort = "abc";
  let showSortCaret = "fa-caret-up";

  var dateOptions = {
    timeZone: "America/New_York",
    hour12: false,
    hour: "2-digit",
    hourCycle: "h24",
  };
  let estDate = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  let d = new Date(estDate);
  let hour = d.getHours();
  let day = d.getDay();
  let minute = d.getMinutes();
  hour = Number(hour);

  let actDay = day;

  if (hour < 9 || (hour == 9 && minute < 30)) {
    actDay = day - 1;
  }

  if (day == 6 || day == 0 || (day == 1 && hour < 9) || (day == 1 && hour == 9 && minute < 30)) {
    actDay = 5;
  }

  let yesterday = mod(actDay - 1, 5);
  if (yesterday == 0) yesterday = 5;
  let twoDaysAgo = mod(actDay - 2, 5);
  if (twoDaysAgo == 0) twoDaysAgo = 5;

  stockData = stockData.sort((a, b) => a?.symbol?.localeCompare(b.symbol));
  stockData = stockData.filter((a) => a);
  let showFavs = stockData.map((a, k) => {
    // upDown difference
    let stockPrice = a.price?.toFixed(2);
    //console.log(a.symbol, stockPrice);

    let upDown = 0;
    let difference = 0;
    let difSymbol = "";
    if (a.series) {
      /*
      if (hour <= 9 || (hour == 9 && minute < 30)) {
        stockPrice = a.series[actDay] || stockPrice;
      }
      */
      let yesterdayEod = a.series[yesterday] || a.series[twoDaysAgo] || stockPrice;
      let todayIsHoliday = isHoliday();
      let yesterdayIsHoliday = isHoliday(true);

      if (todayIsHoliday || yesterdayIsHoliday) {
        yesterdayEod = a.series[twoDaysAgo] || stockPrice;
      }

      upDown = preferences.upDown || "percentage";

      //console.log(a.symbol, yesterday, yesterdayEod);
      if (upDown == "percentage") {
        difference = ((stockPrice / yesterdayEod) * 100 - 100)?.toFixed(2);

        difSymbol = "%";
      } else {
        difference = (stockPrice - yesterdayEod)?.toFixed(2);
      }
    } else {
      difference = "X.XX";
    }

    if (true || difference == "0.00") {
      if (preferences.upDown == "value") {
        difference = a.change;
      } else {
        difference = a.changesPercentage;
      }
    }

    let weatherButtonCss = darkmode ? "favListItemDark" : "favListItem";
    if (activeStock == k) {
      weatherButtonCss = darkmode ? "favListItemDarkActive" : "favListItemActive";
    }
    let showPrice = a.price?.toFixed(2);

    let changeColor = darkmode ? "#5fff5f" : "#0c870c";
    let showCaret = "fa fa-caret-up";
    if (activeStock == k) {
      changeColor = "#5fff5f";
    }
    if (difference < 0) {
      changeColor = darkmode ? "#ef0101" : "#bd0101";
      showCaret = "fa fa-caret-down";
      if (activeStock == k) {
        changeColor = darkmode ? "#ef0101" : "#ffbe9e";
      }
    }
    let showName = a.info?.displayName || null;
    if (!showName) {
      showName = a.info?.shortName || null;
    }

    //undefined api
    if (!difference) {
      difference = "";
      showCaret = "";
      difSymbol = "";
    }

    let yHigh = 0;
    let ylow = 0;
    let chartData = [];
    /*
    let showChart = !isMobile && activeStock == k && a.charts;
    if (showChart && a.charts.max?.name != "Error") {
      let chartCloses = a.charts.max.chartCloses;
      let chartTimestamps = a.charts.max.chartTimestamps;
      yHigh = Math.max(...chartCloses);
      yHigh = Math.ceil(yHigh);
      ylow = Math.min(...chartCloses);
      ylow = Math.floor(ylow);
      chartData = chartTimestamps.map((b, l) => {
        let time1k = b * 1000;
        let localDate = new Date(time1k).toLocaleString("en-US");
        let justDate = localDate.split(",")[0];

        return {
          date: justDate,
          close: chartCloses[l]?.toFixed(2),
        };
      });
    }
    */
    //console.log("chartData", chartData);

    return (
      <SwipeToDelete
        key={a.symbol}
        onDelete={() => props.removeStock(a.symbol)}
        disabled={!isMobile}
        deleteWidth={40}
        deleteThreshold={40}
        deleteColor={"none"}
        className={"swipeRow"}
        deleteComponent={isMobile ? <img src={darkmode ? closeXDark : closeX} className={"removeButtonXSwipe"} /> : <div />}
      >
        <div className={"favListBox"}>
          {false && (
            <div className={"chartDataBox"}>
              <div className={"chartSelect"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{ marginRight: 6, marginLeft: 2 }}
                    onClick={() => {
                      props.chartTime("1d");
                    }}
                  >
                    {`1D`}
                  </div>
                  <div
                    style={{ marginRight: 6, opacity: 0.6 }}
                    onClick={() => {
                      props.chartTime("5d");
                    }}
                  >
                    {`5D`}
                  </div>
                  <div
                    style={{ marginRight: 6, opacity: 0.6 }}
                    onClick={() => {
                      props.chartTime("1m");
                    }}
                  >
                    {`1M`}
                  </div>
                  <div
                    style={{ marginRight: 6, opacity: 0.6 }}
                    onClick={() => {
                      props.chartTime("1y");
                    }}
                  >
                    {`1Y`}
                  </div>
                  <div
                    style={{ opacity: 0.6 }}
                    onClick={() => {
                      props.chartTime("max");
                    }}
                  >
                    Max
                  </div>
                </div>
              </div>
              <LineChart width={300} height={140} data={chartData}>
                <Line type="monotone" dataKey="close" stroke="#aaa" strokeWidth={1} dot={false} animationDuration={300} />
                <Tooltip />
                <XAxis dataKey="date" hide />
                <YAxis domain={[ylow, yHigh]} hide />
              </LineChart>
            </div>
          )}
          <button
            className={weatherButtonCss}
            onClick={() => (isMobile ? props.inactiveStock(a.symbol, a.inactive) : props.makeStockActive(k))}
          >
            <div className={"favListLeft"}>
              <RssButton
                onClick={() => !isMobile && props.inactiveStock(a.symbol, a.inactive)}
                activeRow={true}
                inactive={a.inactive}
                darkmode={darkmode}
              />
              <div className={darkmode ? "favListSymbolDark" : "favListSymbol"}>{a.symbol}</div>
              <div className={darkmode ? "favListNameDark" : "favListName"} style={{ maxWidth: activeStock == k ? 104 : 134 }}>
                <div style={{ overflow: "hidden", width: 134 }}>{showName}</div>
              </div>
            </div>
            <div className={"favListRight"}>
              <div className={`${showCaret} favItemCaret`} style={{ color: changeColor }}></div>
              <div className={darkmode ? "favItemChangeDark" : "favItemChange"} style={{ color: changeColor }}>
                {`${difference}${difSymbol}`}
              </div>
              <div className={darkmode ? "favItemStockPriceDark" : "favItemStockPrice"}>{a.price?.toFixed(2)}</div>
            </div>
          </button>
          {activeStock == k && !isMobile && (
            <button onClick={() => props.removeStock(a.symbol)}>
              <img src={darkmode ? closeXDark : closeX} className={"removeButtonX"} />
            </button>
          )}
        </div>
      </SwipeToDelete>
    );
  });

  let filteredList = props.showFiltered.map((a, k) => {
    return (
      <div key={k} className={"favListBox"}>
        <button
          className={darkmode ? "favListItemDark" : "favListItem"}
          onClick={() => a.name != "results" && props.onPressStockFilter(a.symbol, false)}
        >
          <div className={"favListLeftFiltered"}>
            <div className={"favListSymbol"}>{a.symbol}</div>
            <div className={"favListName"}>{a.name}</div>
          </div>
          <div className={"favListRight"}>
            <div className={"searchSubmitButtonBoxFilter"}>
              <div className={"submitPlusFontFilter"}>{a.name != "results" && "+"}</div>
            </div>
          </div>
        </button>
      </div>
    );
  });

  let togglePercentage = (
    <div className={"settingsChangeText"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 2,
          marginTop: 1,
        }}
      >
        <div style={{ opacity: darkmode ? 0.9 : 0.6, fontSize: 18 }}>$</div>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          <Switch
            style={{ height: 18, width: 28, marginTop: -3 }}
            styles={{
              track: {
                backgroundColor: darkmode ? "#555" : "#bbb",
              },
              trackChecked: {
                backgroundColor: darkmode ? "#728cd4" : "#99ace0",
              },
            }}
            onChange={handleSettingsChange}
            value={preferences.upDown == "value" ? 0 : 1}
          />
        </div>
        <div style={{ opacity: darkmode ? 0.9 : 0.6, fontSize: 18 }}>%</div>
      </div>
    </div>
  );

  return (
    <div className={darkmode ? "prefBoxDark" : "prefBox"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: "space-between",
          paddingLeft: 5,
          marginBottom: 3,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className={"weatherForm"}>
            <form
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onSubmit={props.onStockSubmit}
            >
              <input
                type="text"
                className={darkmode ? "searchSubmitBoxDark" : "searchSubmitBox"}
                value={stockInput}
                onChange={props.onStockInput}
                placeholder="STOCK / ETF"
              />
              {loadingStock ? (
                <div className={"searchSubmitButtonBox"}>
                  <div className={"loader"} style={{ marginLeft: 2, marginTop: 6 }} />
                </div>
              ) : (
                <div className={"searchSubmitButtonBox"}>
                  <input type="submit" className={darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"} value="" />
                  <div className={darkmode ? "submitPlusFontDark" : "submitPlusFont"}>+</div>
                </div>
              )}
            </form>
          </div>
          <button className={"searchBlackBox"} style={{ display: "none" }}>
            <img src={searchBlack} className={"searchBlack"} />
          </button>
        </div>

        <div onClick={() => props.alternateSort("stocks")} className={"favSort noselect"} style={{ display: "none" }}>
          <div className={`fa ${showSortCaret} favSortCaret noselect`}></div>
          <div className={"noselect"}>{showStockSort}</div>
        </div>
        {togglePercentage}
      </div>
      {filteredList.length > 0 && (
        <>
          <div className={"favListFiltered"}>{filteredList}</div>
          <div className={"smallDivider"} />
        </>
      )}
      {networkErr && <div className={"networkErr"}>{networkErr}</div>}
      <div className={"favList"}>{showFavs}</div>
    </div>
  );
}

export default ListStocks;
