import React, { useEffect, useState } from "react";

import useWebSocket from "react-use-websocket";
const socketUrl = "wss://quotron.co:7072";

function LittleSocket(props) {
  const [messageHistory, setMessageHistory] = useState([]);
  const [pingTimes, setPingTimes] = useState({});
  let [filter, setFilter] = useState("");
  let [filterTime, setFilterTime] = useState("");

  const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: () => console.log("opened"),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      let showDate = new Date().toLocaleString();
      lastMessage.date = showDate;
      setMessageHistory((prev) => prev.concat(lastMessage));

      let showId = lastMessage.data.split("~")[1];
      setPingTimes((prev) => {
        const newPingTimes = { ...prev };
        if (!newPingTimes[showId]) {
          newPingTimes[showId] = [];
        }
        newPingTimes[showId].push(new Date().getTime());
        return newPingTimes;
      });
    }
  }, [lastMessage, setMessageHistory]);

  const calculateAveragePing = (times) => {
    if (times.length < 2) return 0;
    let totalDiff = 0;
    for (let i = 1; i < times.length; i++) {
      totalDiff += times[i] - times[i - 1];
    }
    return totalDiff / (times.length - 1) / 1000; // convert to seconds
  };

  const calculatePingsPerSecond = () => {
    const allTimes = Object.values(pingTimes).flat();
    if (allTimes.length < 2) return 0;
    const totalTime = (Math.max(...allTimes) - Math.min(...allTimes)) / 1000; // convert to seconds
    return (allTimes.length / totalTime).toFixed(2);
  };

  let historyReverse = messageHistory.reverse();
  let showHistory = historyReverse.map((a, k) => {
    let showId = a.data.split("~")[1];
    if (filter) {
      let idNon = showId?.replaceAll("-", "");
      let filterNon = filter?.replaceAll("-", "");

      if (idNon.includes(filterNon)) {
        return (
          <div
            key={k}
            style={{
              marginTop: 3,
              display: "flex",
              alignSelf: "stretch",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{showId}</div> <div>{a.date}</div>
          </div>
        );
      }
    } else {
      return (
        <div
          key={k}
          style={{
            marginTop: 3,
            display: "flex",
            alignSelf: "stretch",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>{showId}</div> <div>{a.date}</div>
        </div>
      );
    }
  });

  let pingTable = Object.entries(pingTimes)
    .map(([id, times], index) => {
      const avgPing = calculateAveragePing(times).toFixed(2);
      if (avgPing === "0.00") return null;
      if (filterTime && !id.includes(filterTime)) return null;
      const modifiedId = id.substring(id.indexOf("-") + 1);
      return { id: modifiedId, times, avgPing };
    })
    .filter(Boolean)
    .sort((a, b) => parseFloat(a.avgPing) - parseFloat(b.avgPing))
    .map(({ id, times, avgPing }, index) => (
      <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{id}</div>
        <div>{times.length}@</div>
        <div>{avgPing} sec</div>
      </div>
    ));

  return (
    <div className={"flexCol"}>
      <div className={"flexRow"}>
        <div>newest to top</div>
        <div style={{ marginLeft: 2 }}>@ {calculatePingsPerSecond()} pings/sec</div>
      </div>

      <input
        type="text"
        className={"portfolioShares"}
        style={{ minWidth: 240, marginBottom: 4 }}
        value={filter}
        onChange={() => setFilter(event.target.value)}
        placeholder={"0000-000-000-000-000"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          height: 200,
          border: "1px solid #999",
          width: 326,
          marginBottom: 20,
        }}
      >
        {showHistory}
      </div>
      <div>avg ping time</div>
      <input
        type="text"
        className={"portfolioShares"}
        style={{ minWidth: 240, marginBottom: 4 }}
        value={filterTime}
        onChange={() => setFilterTime(event.target.value)}
        placeholder={"0000-000-000-000-000"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          height: 100,
          border: "1px solid #999",
          width: 326,
        }}
      >
        {pingTable}
      </div>
    </div>
  );
}
export default LittleSocket;
